require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

const images = require.context("../images", true);

import "./bootstrap_custom.js";
import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/application.scss";

import { Accordion } from "bootstrap";

$(document).on("turbolinks:load", function () {
  $("#searchbox").on("shown.bs.collapse", function () {
    $("#q_doc_cont_all").focus();
  });
});
